import { defineComponent, PropType } from 'vue';
import { getPages } from '@/utils';


interface LaravelPagination {
  meta: LaravelPaginationMeta;
}

interface LaravelPaginationMeta {
  current_page?: number;
  current?: number;
  last_page?: number;
  last?: number;
}

export default defineComponent({
  name: 'Pagination',
  props: {
    autoHide: Boolean,
    data: Object as PropType<LaravelPagination>,
    max: {type: Number, default: 7},
  },
  emits: ['page'],
  computed: {
    meta(): LaravelPaginationMeta {
      return this?.data?.meta || {};
    },
    current(): number {
      return this.meta?.current_page || this.meta?.current || 1;
    },
    pages(): number {
      return this.meta?.last_page || this.meta?.last || 1;
    },
    pagination(): number[] {
      const { current, max, pages } = this;
      return getPages({ current, max, pages });
    },
  },
  methods: {
    goTo(page: number) {
      if (page !== this.current) this.$emit('page', page);
    },
  },
  render() {
    if (this.autoHide && this.pages < 2) return;
    return (
      <div class="bg-white px-2 py-2 flex items-center justify-between">
        <div class="paginator flex items-center justify-between w-full border-t border-gray-300">
          <button class="page prev flex items-center space-x-2" disabled={this.current === 1} onClick={() => this.goTo(this.current - 1)}>
            <r-icon type="arrow-left" class="h-5" />
            <span>Anterior</span>
          </button>
          <nav class="pages hidden sm:inline-flex" aria-label="Pagination">
            {this.pagination.map(page => page ? (
              <button class={['page', {active: this.current===page}]} onClick={() => this.goTo(page)}>
                {page}
              </button>
            ) : (
              <span class="item">...</span>
            ))}
          </nav>
          <nav class="pages inline-flex sm:hidden" aria-label="Pagination">
            <button class="page active">{this.current}</button>
          </nav>
          <button class="page next flex items-center space-x-2" disabled={this.current === this.pages} onClick={() => this.goTo(this.current + 1)}>
            <span>Siguiente</span>
            <r-icon type="arrow-right" class="h-5" />
          </button>
        </div>
      </div>
    );
  },
});
