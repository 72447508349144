<template>
  <file-pond
    v-if="file === null || edit"
    ref="pond"
    :name="name"
    class-name="my-pond"
    v-bind="es_ES"
    :allow-remove="false"
    :server="server"
    :allow-multiple="allowMultiple"
    :accepted-file-types="acceptedFileTypes"
  />
  <div v-else class="p-4 border bg-white">
    <div class="bg-green-600 rounded-md p-2 text-white flex justify-between">
      Archivo Guardado
      <div class="bg-green-900 rounded-full w-7 h-7 grid place-content-center cursor-pointer hover:border-white hover:border-2" @click="edit = true">
        <r-icon type="edit" class="h-4" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import 'filepond/dist/filepond.min.css';
import '@/assets/styles/filepond_theme.css';
import es_ES from 'filepond/locale/es-es.js';

// Create FilePond component
const FilePond = vueFilePond( FilePondPluginFileValidateType );
export default defineComponent({
  components: {
    FilePond,
  },
  props: {
    name: String,
    allowMultiple: Boolean,
    acceptedFileTypes: String,
    file: String,
  },
  setup() {
    const match = document.cookie.match(new RegExp('(^|;\\s*)(XSRF-TOKEN)=([^;]*)'));
    const token = (match ? decodeURIComponent(match[3]) : null);
    const server = {
      url: `${process.env.VUE_APP_BACKEND}`,
      timeout: 7000,
      process: {
        url: 'api/user/files',
        method: 'POST',
        headers: {
          'X-XSRF-TOKEN': token,
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
        withCredentials: true,
      },
      remove: null,
    };
    return {server, es_ES};
  },
  data() {
    return {
      edit: false,
    };
  },
  methods: {
  },
});
</script>
