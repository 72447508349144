
import LabelFormProfile from '@/components/UI/LabelFormProfile.vue';
import InputFormProfile from '@/components/UI/InputFormProfile.vue';
import SelectFormProfile from '@/components/UI/SelectFormProfile.vue';
import ErrorField from '@/components/UI/ErrorField.vue';
import { update, useUser } from '@/store/security';
import { useForm, useField } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LabelFormProfile,
    InputFormProfile,
    SelectFormProfile,
    ErrorField,
  },
  setup() {
    const user = useUser();

    const schema = {
      name: 'min:3|max:60|required',
      phone: 'max:20|alpha_num',
      rfc: 'max:18|alpha_num',
      address_number: 'max:7|numeric',
      street: 'max:30|alpha_num',
      col: 'max:30|alpha_spaces',
      mun: 'max:30|alpha_spaces',
      postal_code: 'max:5|numeric',
      state: 'max:20|alpha_spaces',
    };

    const labels = {
      name: 'Nombre',
      phone: 'Teléfono',
      rfc: 'RFC',
      address_number: 'Numero Exterior',
      street: 'Calle',
      col: 'Colonia',
      mun: 'Municipio',
      postal_code: 'Codigo Postal',
      state: 'Estado',
    };

    const { errors, values, meta } = useForm({
      initialValues: {...user.value},
      validationSchema: schema,
    });

    Object.entries(labels).forEach(([key, val])=>{
      useField(key, null, {label: val});
    });

    return {
      errors,
      meta,
      email: user.value?.email,
      form: values,
    };
  },
  data: () =>({
    loading: false,
  }),
  methods: {
    onSubmit() {
      this.loading = true;
      update(this.form).then(()=> this.loading = false);
      this.$router.push({ name: 'profile' });
    },
  },
});
