
import { logout, useUser, openModal } from '@/store/security';
import { itemsInCart } from '@/store/shoppingCart';
import { defineComponent } from '@vue/runtime-core';
import Forgot from './Modals/Forgot.vue';
import Login from './Modals/Login.vue';
import Register from './Modals/Register.vue';

export default defineComponent({
  name: 'HeaderProfile',
  components: {
    Forgot,
    Login,
    Register,
  },
  props: {
    msg: String,
  },
  setup() {
    const user = useUser();
    return { itemsInCart, logout, user, openModal };
  },
});
