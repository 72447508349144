<template>
  <ul class="divide-gray-200 divide-y -my-px" aria-disabled="true">
    <li v-if="order?.item" class="bg-opacity-50 bg-gray-100 hover:bg-gray-200 p-3 space-y-2">
      <div class="flex justify-end items-center">
        <promise-button v-if="$route.name === 'operation' && order.status < 4" class="btn-sm btn-secondary" :promise="cancelPromise" disable-on-promise @click="cancel()">
          <template #default>
            Cancelar
          </template>
          <template #fallback>
            Cancelando...
          </template>
        </promise-button>
      </div>
      <ItemDetails :item="order?.item" :details="order" />
    </li>
  </ul>
</template>

<script lang="ts">
import ItemDetails from './ItemDetails.vue';
import { currency, date } from '@/filters';
import { defineComponent, PropType } from 'vue';
import { cancelOrder } from '@/store/operations';
import { Order } from '@/store/models/operations';


export default defineComponent({
  name: 'Order',
  components: { ItemDetails },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup() {
    return { currency, date };
  },
  data: () => ({
    cancelPromise: null as Promise<any> | null,
  }),
  methods: {
    cancel() {
      this.cancelPromise = cancelOrder(this.order.uuid).finally(() => {
        this.cancelPromise = null;
      });
    },
  },
});
</script>

<style></style>
