
import LabelFormProfile from '@/components/UI/LabelFormProfile.vue';
import InputFormProfile from '@/components/UI/InputFormProfile.vue';
import ErrorField from '@/components/UI/ErrorField.vue';

import { update, refUser } from '@/store/security';
import { useForm, useField } from 'vee-validate';
import { defineComponent } from 'vue';
import { User } from '@/store/models/security';

export default defineComponent({
  components: {
    LabelFormProfile,
    InputFormProfile,
    ErrorField,
  },
  setup() {
    const user = refUser() as unknown as User;
    const schema = {
      company: 'min:3|max:60|required|alpha_spaces',
      name: 'min:3|max:60|required|alpha_spaces',
      phone: 'min:10|max:20|numeric|required',
    };

    const labels = {
      company: 'Empresa',
      name: 'Nombre',
      phone: 'Teléfono',
    };

    const { errors, values, meta, resetForm } = useForm({
      initialValues: {
        company: '',
        name: '',
        phone: '',
        loading: false,
      },
      validationSchema: schema,
    });

    Object.entries(labels).forEach(([key, val]) => {
      useField(key, null, {label: val});
    });

    return {
      errors,
      form: values,
      meta,
      resetForm,
      user,
    };
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    onSubmit() {
      this.loading = true;
      update({references: [this.form, ...(this.user.references ?? [])]}).then(()=> {
        this.loading = false;
        this.resetForm();
        this.meta.valid = false;
      });
    },
    onDelete(index: any) {
      this.user.references.splice(index, 1);
      update({references: [...(this.user.references ?? [])]});
    },
  },
});
