<template>
  <section class="md:px-20">
    <h1 class="uppercase font-secondary font-bold text-secondary text-3xl">Vista general de la cuenta</h1>
    <h2 class="uppercase text-primary font-bold text-lg mt-9">Perfil</h2>
    <table class="w-full">
      <tbody>
        <tr class="border-b">
          <td class="w-1/3 pt-6 pb-2 text-xs font-bold text-secondary">Tipo de usuario</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">
            {{ user.fiscal_type == "moral" ? "Persona Moral" : "Persona  Física" }}
          </td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Nombre / Razon social</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">{{ user.name }}</td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Email</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">{{ user.email }}</td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Teléfono</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">{{ user.phone }}</td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">RFC</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left uppercase">{{ user.rfc }}</td>
        </tr>
      </tbody>
    </table>
    <h2 class="uppercase text-primary font-bold text-lg mt-9">Dirección</h2>
    <table class="w-full">
      <tbody>
        <tr class="border-b">
          <td class="w-1/3 pt-6 pb-2 text-xs font-bold text-secondary">Calle</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left capitalize">
            {{ user.street }}
          </td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Número</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">
            {{ user.address_number }}
          </td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Colonia</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left capitalize">
            {{ user.col }}
          </td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Municipio</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left capitalize">
            {{ user.mun }}
          </td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Codigo postal</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">{{ user.postal_code }}</td>
        </tr>
        <tr class="border-b">
          <td class="pt-6 pb-2 text-xs font-bold text-secondary">Estado</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left capitalize">
            {{ user.state }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex mt-4 justify-end">
      <router-link :to="{ name: 'edit_profile' }" class="btn btn-success flex items-center">
        <r-icon type="update" class="h-5 mr-2 inline-block" />
        Editar Perfil
      </router-link>
    </div>
  </section>
</template>

<script>
import { useUser } from '@/store/security';
export default {
  setup() {
    const user = useUser();
    return { user };
  },
};
</script>

<style></style>
