<template>
  <component :is="path === 'operation' ? 'div' : 'router-link'" class="block" :to="{ name: 'operation', params: { uuid: operation.uuid }}">
    <div key="{operation.id}" class="overflow-hidden divide-gray-200 divide-y border rounded-lg" aria-disabled="true">
      <div class="bg-opacity-50 bg-white hover:bg-orange-200 p-3 space-y-2">
        <div class="flex items-center justify-between">
          <div class="text-sm text-chamb-600 truncate">
            <span class="font-medium mr-1">Folio N°:</span>
            {{ operation.uuid }}
          </div>
          <div class="text-sm">
            <span class="font-medium mr-1">Fecha:</span>
            {{ datetime(operation.created_at) }}
          </div>
        </div>
      </div>
      <div v-if="operation.orders.length">
        <Order v-for="order in operation.orders" :key="'order_' + order.id" :order="order" />
      </div>
      <div class="bg-opacity-50 bg-white hover:bg-gray-100">
        <div class="block p-3 space-y-2">
          <div class="grid grid-cols-2">
            <div class="space-y-2">
            </div>
            <div class="space-y-2 md:mx-20">
              <div class="flex justify-between">
                <div class="text-sm text-primary font-semibold">Subtotal</div>
                <div class="tracking-wider text-sm text-gray-600 font-semibold">{{ currency(sub_total) }}</div>
              </div>
              <div class="flex justify-between ">
                <div class="text-sm text-primary font-semibold">IVA 16%</div>
                <div class="tracking-wider text-sm text-gray-600 font-semibold">{{ currency(taxes) }}</div>
              </div>
              <div class="flex justify-between ">
                <div class="text-secondary font-semibold">TOTAL</div>
                <div class="tracking-wider text-secondary font-semibold">{{ currency(total) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import { datetime, currency } from '@/filters';
import Order from './Order.vue';
import { defineComponent } from 'vue';
import { Order as OrderInterface } from '@/store/models/operations';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Operation',
  components: {
    Order,
  },
  props: {
    operation: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const unfilteredOrders = props.operation.orders as OrderInterface[];
    const orders = unfilteredOrders.filter(o => o.status !== 6);
    const sub_total =  orders.reduce((t, o) => +t + +o.sub_total + +o.delivery_fee + +o.insurance_fee, 0);
    const taxes =  orders.reduce((t, o) => +t + +o.tax, 0);
    const total =  orders.reduce((t, o) => +t + +o.total, 0);
    const path = useRoute().name;
    
    return { datetime, currency, sub_total, taxes, total, path };
  },
});
</script>

<style>

</style>
