<template>
  <section class="md:px-20">
    <h1 class="uppercase font-secondary font-bold text-secondary text-3xl">Referencias Comerciales</h1>
    <h2 class="uppercase text-primary font-bold text-lg mt-9">Contactos / Proveedores</h2>
    <form v-if="form !== null" @submit.prevent="onSubmit">
      <div class="flex -mx-3">
        <div class="mt-6 w-1/2 px-3">
          <LabelFormProfile>Empresa</LabelFormProfile>
          <InputFormProfile
            v-model="form.company"
            name="company"
            placeholder="Empresa"
          />
          <ErrorField>{{ errors.company }}</ErrorField>
        </div>
        <div class="mt-6 w-1/2 px-3">
          <LabelFormProfile>Nombre</LabelFormProfile>
          <InputFormProfile
            v-model="form.name"
            name="name"
            placeholder="Nombre del contacto"
          />
          <ErrorField>{{ errors.name }}</ErrorField>
        </div>
        <div class="mt-6 w-1/2 px-3">
          <LabelFormProfile>Teléfono</LabelFormProfile>
          <InputFormProfile
            v-model="form.phone"
            name="phone"
            placeholder="Teléfono"
          />
          <ErrorField>{{ errors.phone }}</ErrorField>
        </div>
      </div>
      <div>
        <button type="submit" class="my-6 btn btn-success float-right" :disabled="!meta.valid || loading">
          <r-icon v-show="loading" type="spinner" class="h-5 inline-block" />
          Guardar
        </button>
      </div>
    </form>
    <table class="w-full">
      <tbody>
        <tr v-for="(contact, index) in user.references" :key="index" class="border-b">
          <td class="w-1/3 pt-6 pb-2 text-xs font-bold text-secondary">{{ contact.company }}</td>
          <td class="w-1/3 pt-6 pb-2 text-xs font-bold text-secondary">{{ contact.name }}</td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">
            {{ contact.phone }}
          </td>
          <td class="pt-6 pb-2 text-xs text-right lg:text-left">
            <button v-if="user.references.length > 3" class="btn btn-primary float-right" @click="onDelete(index)">
              Borrar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script lang="ts">
import LabelFormProfile from '@/components/UI/LabelFormProfile.vue';
import InputFormProfile from '@/components/UI/InputFormProfile.vue';
import ErrorField from '@/components/UI/ErrorField.vue';

import { update, refUser } from '@/store/security';
import { useForm, useField } from 'vee-validate';
import { defineComponent } from 'vue';
import { User } from '@/store/models/security';

export default defineComponent({
  components: {
    LabelFormProfile,
    InputFormProfile,
    ErrorField,
  },
  setup() {
    const user = refUser() as unknown as User;
    const schema = {
      company: 'min:3|max:60|required|alpha_spaces',
      name: 'min:3|max:60|required|alpha_spaces',
      phone: 'min:10|max:20|numeric|required',
    };

    const labels = {
      company: 'Empresa',
      name: 'Nombre',
      phone: 'Teléfono',
    };

    const { errors, values, meta, resetForm } = useForm({
      initialValues: {
        company: '',
        name: '',
        phone: '',
        loading: false,
      },
      validationSchema: schema,
    });

    Object.entries(labels).forEach(([key, val]) => {
      useField(key, null, {label: val});
    });

    return {
      errors,
      form: values,
      meta,
      resetForm,
      user,
    };
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    onSubmit() {
      this.loading = true;
      update({references: [this.form, ...(this.user.references ?? [])]}).then(()=> {
        this.loading = false;
        this.resetForm();
        this.meta.valid = false;
      });
    },
    onDelete(index: any) {
      this.user.references.splice(index, 1);
      update({references: [...(this.user.references ?? [])]});
    },
  },
});
</script>

<style></style>
