<template>
  <li class="border-0 mx-20">
    <router-link
      class="py-3 block hover:text-primary text-xs "
      :class="active ? 'text-primary font-bold' : 'text-secondary text-xs'"
      :to="{ name: to }"
    >
      <r-icon :type="icon" class="h-4 text-xs inline-block" /> <span class="ml-3">{{ label }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true },
    icon: { type: String, required: true  },
    to: { type: String, required: true },
    active: { type: Boolean, required: false },
  },
};
</script>

<style></style>
