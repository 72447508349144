<template>
  <section class="md:px-20">
    <h1 class="uppercase font-secondary font-bold text-secondary text-3xl">Documentación</h1>
    <div v-for="(value, name) in form" :key="name" class="mt-6 px-3">
      <span class="text-secondary my-2 block">{{ value }}</span>
      <FilePondInput
        :name="name"
        :file="user[name]"
        :allow-multiple="false"
        accepted-file-types="application/pdf, image/jpeg, image/png"
      />
    </div>
  </section>
</template>

<script lang="ts">
import FilePondInput from '@/components/UI/FilePondInput.vue';
import { useUser } from '@/store/security';
import { defineComponent } from 'vue';



export default defineComponent({
  components: {
    FilePondInput,
  },
  setup() {
    const user = useUser();
    return { user };
  },
  data: function() {
    return {
      form: {
        constitutive_act: 'Acta constitutiva y poderes',
        proof_of_address_one: 'Primer comprobante de domicilio',
        proof_of_address_two: 'Segundo comprobante de domicilio',
        legal_representative_identification_front: 'Indentificacion del reprecentante legal de la compañia (Frente)',
        legal_representative_identification_back: 'Indentificacion del reprecentante legal de la compañia (Reverso)',
        sat_registrations: 'Alta en hacienda y cedula del R.F.C.',
        sat_compliance_letter: 'Carta de opinion sat de cumplimiento de obligaciones fiscales',
      },
    };
  },
  methods: {
  },
});
</script>

<style></style>
