
import ItemDetails from './ItemDetails.vue';
import { currency, date } from '@/filters';
import { defineComponent, PropType } from 'vue';
import { cancelOrder } from '@/store/operations';
import { Order } from '@/store/models/operations';


export default defineComponent({
  name: 'Order',
  components: { ItemDetails },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup() {
    return { currency, date };
  },
  data: () => ({
    cancelPromise: null as Promise<any> | null,
  }),
  methods: {
    cancel() {
      this.cancelPromise = cancelOrder(this.order.uuid).finally(() => {
        this.cancelPromise = null;
      });
    },
  },
});
