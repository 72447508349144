
import { defineComponent } from '@vue/runtime-core';
import Pagination from '@/components/Pagination';
import { useOperations, getOperations } from '@/store/operations';
import OperationsList from '@/components/Operations';
import { ref } from 'vue';

export default defineComponent({
  name: 'OperationsView',
  components: {
    Pagination,
    OperationsList,
  },
  setup() {
    const operations = useOperations();
    const isLoading = ref(false);
    const currentPage = ref(1);
    const load = (page: number = 1) => {
      if (isLoading.value) return;
      isLoading.value = true;
      getOperations(page).then(() => {
        currentPage.value = page;
      }).finally(() => {
        isLoading.value = false;
      });
    };

    load();

    return {
      operations,
      load,
      isLoading,
      currentPage,
    };
  },
});
