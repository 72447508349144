
import { datetime, currency } from '@/filters';
import Order from './Order.vue';
import { defineComponent } from 'vue';
import { Order as OrderInterface } from '@/store/models/operations';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Operation',
  components: {
    Order,
  },
  props: {
    operation: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const unfilteredOrders = props.operation.orders as OrderInterface[];
    const orders = unfilteredOrders.filter(o => o.status !== 6);
    const sub_total =  orders.reduce((t, o) => +t + +o.sub_total + +o.delivery_fee + +o.insurance_fee, 0);
    const taxes =  orders.reduce((t, o) => +t + +o.tax, 0);
    const total =  orders.reduce((t, o) => +t + +o.total, 0);
    const path = useRoute().name;
    
    return { datetime, currency, sub_total, taxes, total, path };
  },
});
