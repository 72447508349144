<template>
  <div class="p-5">
    <router-link :to="{name:'operations'}" class="text-primary font-semibold uppercase m-4 inline-flex items-center">
      <r-icon type="chevronLeft" class="inline w-7" />Regresar
    </router-link>
    <div class="border rounded-lg p-4 bg-white mb-4">
      <div v-if="blockedStatus">
        <h2 class="text-2xl font-semibold text-secondary">Orden Bloqueada</h2>
        <table class="w-full">
          <tbody>
            <tr>
              <td class="font-bold text-xs uppercase mr-2 text-primary">Motivo:</td>
              <td>Documentacion Pendiente</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="status == 'pending_payment'" class="flex flex-col space-y-2">
        <h2 class="text-2xl font-semibold text-secondary">Pendiente de Pago</h2>
        <table class="w-full">
          <tbody>
            <tr>
              <td class="font-bold text-xs uppercase mr-2 text-primary">Metodo de Pago:</td>
              <td>Transferencia Bancaria SPEI</td>
            </tr>
            <tr>
              <td class="font-bold text-xs uppercase mr-2 text-primary">Banco:</td>
              <td>{{ chargeDetails.payment_method.bank }}</td>
            </tr>
            <tr>
              <td class="font-bold text-xs uppercase mr-2 text-primary">No. de Cuenta:</td>
              <td>{{ chargeDetails.payment_method.receiving_account_number }}</td>
            </tr>
            <tr>
              <td class="font-bold text-xs uppercase mr-2 text-primary">Fecha de expiración:</td>
              <td>{{ speiExpirationDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="status == 'paid'">
        <h2 class="uppercase font-secondary font-semibold text-secondary text-xl">Orden Pagada</h2>
        <table class="w-full">
          <tbody>
            <tr>
              <td class="font-bold text-xs uppercase mr-2 text-primary">Metodo de Pago:</td>
              <td>Transferencia Bancaria SPEI</td>
            </tr>
            <tr>
              <td class="font-bold text-xs uppercase mr-2 text-primary">Fecha y Hora de Pago:</td>
              <td>{{ paidAt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h2 class="text-2xl font-semibold text-primary text-center">Operación Cancelada</h2>
      </div>
    </div>
    <div class="border rounded-lg p-4 bg-white mb-4">
      <h2 class="uppercase font-secondary font-semibold text-secondary">Zona de trabájo</h2>
      <table class="w-full">
        <tbody>
          <tr>
            <td class="text-primary font-bold uppercase text-xs">Ubicacion:</td>
            <td>{{ operation.location.value }}</td>
          </tr>
          <tr>
            <td class="text-primary font-bold uppercase text-xs">Colonia:</td>
            <td>{{ operation.city }}</td>
          </tr>
          <tr>
            <td class="text-primary font-bold uppercase text-xs">Calle:</td>
            <td>{{ operation.street }}</td>
          </tr>
          <tr>
            <td class="text-primary font-bold uppercase text-xs">No. exterior:</td>
            <td>{{ operation.exterior_number }}</td>
          </tr>
          <tr>
            <td class="text-primary font-bold uppercase text-xs">Codigo postal:</td>
            <td>{{ operation.postal_code }}</td>
          </tr>
          <tr>
            <td class="text-primary font-bold uppercase text-xs">Nombre del contacto:</td>
            <td>{{ operation.contact_name }}</td>
          </tr>
          <tr>
            <td class="text-primary font-bold uppercase text-xs">Teléfono:</td>
            <td>{{ operation.contact_phone }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <OperationComponent class="mb-8" :operation="operation" />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, watch, computed } from 'vue';
import { useOperation, getOperation } from '@/store/operations';
import { date, unixToDateTime } from '@/filters';
import OperationComponent from '@/components/Operations/Operation';

export default defineComponent({
  name: 'OperationView',
  components: {
    OperationComponent,
  },
  props: {
    uuid: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    onBeforeMount(() => getOperation(props.uuid));
    const operation = useOperation();
    const pendingPayment = computed(() => {
      return operation?.value?.orders?.find(order => order.status === 1) !== undefined;
    });
    watch(
      () => operation,
      (operation) => {
        if(!operation?.value.conekta.charges && pendingPayment.value) {
          window.ConektaCheckoutComponents.Integration({
            targetIFrame: '#conektaIframeContainer',
            checkoutRequestId: operation.value.conekta.checkout.id,
            publicKey: 'key_GqGUJbAVrUxka9shYYCzbAg',
            options: {},
            styles: {},
            onFinalizePayment: function (event) {
              console.log('Finalize Payment');
              console.log(event);
            },
          });
        }
      }, {deep: true});

    return { operation, date, pendingPayment };
  },
  computed: {
    status() {
      return this.operation?.conekta.payment_status;
    },
    chargeDetails() {
      return this.operation?.conekta.charges?.data[0];
    },
    speiExpirationDate() {
      return unixToDateTime(this.operation?.conekta.charges?.data[0].payment_method.expires_at);
    },
    paidAt() {
      return unixToDateTime(this.operation?.conekta.charges?.data[0].paid_at);
    },
    blockedStatus() {
      return this.operation?.orders.find(order => order.status === 2) !== undefined;
    },
  },
});
</script>
