import Operation from './Operation.vue';

export default function Operations(props: any) {
  return props.operations?.length ? (
    <div class="space-y-4">
      {props.operations.map((operation: any) => (
        <Operation key={operation.uuid} operation={operation} />
      ))}
    </div>
  ) : (
    <div class="border rounded-lg text-center text-lg p-3">
      No tiene órdenes.
    </div>
  );
}
