<template>
  <div class="bg-white">
    <tw-container is="nav" class="py-3 flex items-center justify-between font-secondary uppercase">
      <router-link to="/" class="font-bold flex items-center">
        <r-icon type="logo" color class="h-12 mr-3" />
        <span class="font-secondary text-3xl text-secondary">RENTA</span>
        <span class="font-secondary text-3xl text-primary">LO</span>
      </router-link>
      <div class="fixed bottom-0 inset-x-0 z-10 bg-white border-2 p-5 / md:static md:bg-transparent md:border-0 md:p-0">
        <div class="space-x-3 md:space-x-5 text-gray-800 font-medium text-center flex items-center justify-center">
          <a href="#" class="p-1">Ayuda</a>
          <template v-if="user !== null">
            <a href="#" @click="logout()">
              Cerrar sesión
            </a>
            <tw-button :to="{ name: 'profile' }" class="uppercase bg-secondary text-white hover:bg-primary md:hover:bg-primary md:hover:text-white">
              {{ user.name }}
            </tw-button>
          </template>
          <router-link id="carrito" :to="{ name: 'Quote' }">
            <tw-button-icon class="group focus:outline-none bg-primary text-white hover:bg-secondary ">
              <r-icon type="cart" class="h-6" />
              <tw-button-icon is="div" v-if="itemsInCart > 0" id="carrito-items" class="bg-secondary group-hover:bg-primary md:group-hover:bg-secondary text-white font-primary text-xxs absolute top-0 right-0 p-2.5 transform translate-x-1 -translate-y-1">
                {{ itemsInCart > 9 ? '9+' : itemsInCart }}
              </tw-button-icon>
            </tw-button-icon>
          </router-link>
        </div>
      </div>
    </tw-container>
    <Forgot />
    <Login />
    <Register />
  </div>
</template>

<script lang="ts">
import { logout, useUser, openModal } from '@/store/security';
import { itemsInCart } from '@/store/shoppingCart';
import { defineComponent } from '@vue/runtime-core';
import Forgot from './Modals/Forgot.vue';
import Login from './Modals/Login.vue';
import Register from './Modals/Register.vue';

export default defineComponent({
  name: 'HeaderProfile',
  components: {
    Forgot,
    Login,
    Register,
  },
  props: {
    msg: String,
  },
  setup() {
    const user = useUser();
    return { itemsInCart, logout, user, openModal };
  },
});
</script>

<style></style>
