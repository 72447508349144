
<template>
  <div class="flex-none md:flex text-left border-b">
    <div class="flex-none">
      <tw-box :ratio="4/3" class="md:w-32">
        <BgImage
          class="w-full h-full cursor-pointer bg-center bg-no-repeat bg-contain border border-gray-600 rounded overflow-hidden"
          :empty="require('@/assets/full-logo.svg')"
          under="#ffffff"
          :src="Array.isArray(item.gallery) && item.gallery.length ? item.gallery[0].url : item.thumbnail"
          @click="openDetails"
        />        
        <!-- item?.thumbnail -->
      </tw-box>      
    </div>
    <div class="flex-grow ml-3 md:w-2/4">
      <h2 class="text-xl text-secondary font-semibold">
        Modelo:
      </h2>
      <router-link :to="{name: 'equipment', params: {slug: item.slug}}">
        <h1 class="uppercase text-primary font-semibold font-secondary text-3xl hover:underline">
          {{ item.name }} ({{ item?.year }})
        </h1>
      </router-link>
      <template v-if="!details">
        <div>
          <div class="flex flex-row mt-2 ">
            <r-icon type="location" class="h-4 text-primary mr-2" /> <div class="text-xs font-bold text-gray-600">{{ item?.location?.value || item?.partner_location }}</div>
          </div>
        </div>
        <div
          v-if="!item?.available"
          class="w-full text-left text-sm text-red-600"
        >
          Este articulo no esta disponible en el rango de fechas seleccionado
        </div>
      </template>
      <div v-if="details?.insurance">
        <span class="text-sm text-gray-600 font-semibold">Poliza:</span>
        &nbsp;
        <a
          class="text-lg text-secondary hover:underline"
          :href="details.insurance.policy_file"
          target="_blank"
        >
          Descargar Poliza
        </a>
      </div>
      <div v-if="details?.status_label">
        <span class="text-sm text-gray-600 font-semibold">Estado:</span>
        &nbsp;
        <span
          class="text-lg"
          :class="{
            'text-yellow-600': [1,2].includes(details.status),
            'text-green-600': [3,4,5].includes(details.status),
            'text-red-600': details.status === 6
          }"
        >
          {{ details.status_label }}
        </span>
      </div>
      <div v-if="details && !(item.start_date && item.end_date)" class="">
        <div>
          <span class="text-sm text-gray-600 font-semibold mr-2">Inicia:</span>
          <span>{{ date(details.start_date) }}</span>
        </div>
        <div>
          <span class="text-sm text-gray-600 font-semibold mr-2">Termina:</span>
          <span>{{ date(details.end_date) }}</span>
        </div>
      </div>
    </div>
    <div v-if="actionsAddCart" class="ml-3 flex items-center gap-4">
      <template v-if="item.available">
        <button
          :id="`agregar-carrito-${item.year}-${item.model}`"
          :class="{hidden: isInCart(item)}"
          class="btn btn-primary whitespace-nowrap "
          @click="addItemToCart(item, dateObject)"
        >
          Guardar en Carrito
        </button>
        <router-link
          v-show="isInCart(item)"
          :to="{name: 'Quote'}"
          class="btn btn-secondary whitespace-nowrap"
        >
          Ver Cotización
        </router-link>
        <button
          :id="`borrar-carrito-${item.year}-${item.model}`"
          :class="{hidden: !isInCart(item)}"
          class="btn btn-primary whitespace-nowrap"
          @click="removeFromCart(item)"
        >
          Eliminar del Carrito
        </button>
      </template>
    </div>
    <div v-if="item.start_date && item.end_date" class="flex-grow ml-3 text-sm text-gray-600 md:w-1/5 md:mr-20">
      <div class="pb-2">
        <span>Fechas Seleccionadas:</span>
      </div>
      <div class="flex justify-between uppercase pb-2">
        <span class="font-semibold">INICIA:</span>
        <span>{{ date(item.start_date) }}</span>
      </div>
      <div class="flex justify-between uppercase pb-2">
        <span class="font-semibold">TERMINA:</span>
        <span>{{ date(item.end_date) }}</span>
      </div>
      <div class="flex justify-between pb-2">
        <span class="font-semibold uppercase">Dias:</span>
        <span>{{ item.days }} {{ item.rate === "week_rate"? "dias a tarifa de semana" : item.rate === "month_rate" ? "dias a tarifa de mes" : "" }}</span>
      </div>
      <div class="flex justify-between uppercase pb-2">
        <span class="font-semibold">Tarifa diaria:</span>
        <span>{{ currency(item.daily_fee) }}</span>
      </div>
    </div>
    <div v-if="details" class="flex flex-col justify-end flex-grow ml-3 text-sm text-secondary md:w-1/5">
      <div class="flex justify-between uppercase pb-2">
        <span class="font-semibold">SEGURO:</span>
        <span class="tracking-wider">{{ currency(details.insurance_fee) }}</span>
      </div>
      <div class="flex justify-between uppercase pb-2">
        <span class="font-semibold">FLETE:</span>
        <span class="tracking-wider">{{ currency(details.delivery_fee) }}</span>
      </div>
      <div class="flex justify-between uppercase pb-2">
        <span class="font-semibold">Renta:</span>
        <span class="tracking-wider">{{ currency(details.sub_total) }}</span>
      </div>
    </div>
    <div v-if="!actionsAddCart && removeFromCart" class="flex items-center md:justify-center justify-end md:w-24">
      <div>
        <button
          class="p-2 rounded-full bg-gray-100 flex items-center"
          @click="removeFromCart(item)"
        >
          <r-icon type="trash" class="h-4 text-gray-500 " />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { currency, date } from '@/filters';
import { refStartDate, refEndDate } from '@/store/shoppingCart';
import { defineComponent, PropType } from '@vue/runtime-core';

interface Dates {
  startDate: string|null
  endDate: string|null
}

interface Item {
  id: number
  start_date: string
  end_date: string
}

export default defineComponent({
  name: 'ItemDetails',
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
    removable: Boolean,
    actionsAddCart: Boolean,
    updateItemDate: Boolean,
    details: Object,
    removeFromCart: Function,
    addItemToCart: Function,
    isInCart: Function,
  },
  emits: ['updateAvailableByDate', 'openDetails'],
  setup() {
    const initDate = {
      startDateInit: '' as string|null,
      endDateInit: '' as string|null,
    };

    return { date, currency, initDate };
  },
  computed: {
    dateObject(): Dates {
      return {
        startDate: this.initDate.startDateInit,
        endDate: this.initDate.endDateInit,
      };
    },
  },
  watch: {
    updateItemDate () {
      if (this.isInCart && !this.isInCart(this.item)) {
        this.setInitDate();
      }
    },
  },
  created () {
    this.setInitDate();
  },
  methods: {
    updateItemAvailableByDate (startDate: string, endDate: string) {
      this.initDate.startDateInit = startDate;
      this.initDate.endDateInit = endDate;
      this.$emit('updateAvailableByDate', {
        itemId: this.item.id,
        startDate,
        endDate,
      });
    },
    setInitDate () {
      this.initDate.startDateInit = this.item.start_date ? date(this.item.start_date) : refStartDate().value;
      this.initDate.endDateInit = this.item.end_date ? date(this.item.end_date) : refEndDate().value;
    },
    openDetails () {
      this.$emit('openDetails', this.item);
    },
  },
});
</script>
