<template>
  <main class="min-h-screen grid grid-rows-layout bg-gray-100">
    <HeaderProfile />
    <div v-if="user" class="lg:flex max-w-5xl mx-auto w-full px-5 py-5">
      <div class="lg:w-1/3 lg:border-r">
        <img
          class="rounded-full mx-auto h-32 my-4"
          :src="user.avatar"
          alt=""
          srcset=""
        />
        <ul class="hidden lg:block">
          <SideNavLink
            v-for="section in sections"
            :key="`profile-${section.name}`"
            :to="section.name"
            :label="section.label"
            :icon="section.icon"
            :active="$route.name === section.name"
          />
        </ul>
        <select
          id="title"
          class="border-0 border-black rounded w-full p-2 lg:hidden"
          name="title"
          :value="routeName"
          @change="changeSection($event)"
        >
          <option
            v-for="section in sections"
            :key="`profile-${section.name}`"
            :value="section.name"
          >
            {{ section.label }}
          </option>
        </select>
      </div>
      <div class="lg:w-3/4">
        <router-view />
      </div>
    </div>
    <VueFooter />
  </main>
</template>

<script lang="ts">
import SideNavLink from '@/components/navigation/SideNavLink.vue';
import HeaderProfile from '@/components/Header/HeaderProfile.vue';
import VueFooter from '@/components/Footer.vue';
import { profile, useUser } from '@/store/security';
import { defineComponent, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: 'Profile',
  components: {
    SideNavLink,
    HeaderProfile,
    VueFooter,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const user = useUser();
    watch(user, (newValue) => {
      if(newValue === null) {
        router.push({name: 'Home'});
      }
    });
    const routeName = route.name as string;
    return { user, routeName };
  },
  data: () => ({
    sections: [
      { name: 'profile', label: 'Datos Generales', icon: 'profile' },
      { name: 'references', label: 'Referencias', icon: 'phone' },
      { name: 'documentation', label: 'Documentación', icon: 'documents'  },
      { name: 'edit_profile', label: 'Editar Perfil', icon: 'update'  },
      { name: 'operations', label: 'Órdenes de renta', icon: 'search'  },
    ],
  }),
  async mounted() {
    await profile();
  },
  methods: {
    changeSection(event: Event) {
      this.$router.push({ name: (event.currentTarget as HTMLInputElement)?.value });
    },
  },
});
</script>

<style></style>
