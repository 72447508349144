
import { currency, date } from '@/filters';
import { refStartDate, refEndDate } from '@/store/shoppingCart';
import { defineComponent, PropType } from '@vue/runtime-core';

interface Dates {
  startDate: string|null
  endDate: string|null
}

interface Item {
  id: number
  start_date: string
  end_date: string
}

export default defineComponent({
  name: 'ItemDetails',
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
    removable: Boolean,
    actionsAddCart: Boolean,
    updateItemDate: Boolean,
    details: Object,
    removeFromCart: Function,
    addItemToCart: Function,
    isInCart: Function,
  },
  emits: ['updateAvailableByDate', 'openDetails'],
  setup() {
    const initDate = {
      startDateInit: '' as string|null,
      endDateInit: '' as string|null,
    };

    return { date, currency, initDate };
  },
  computed: {
    dateObject(): Dates {
      return {
        startDate: this.initDate.startDateInit,
        endDate: this.initDate.endDateInit,
      };
    },
  },
  watch: {
    updateItemDate () {
      if (this.isInCart && !this.isInCart(this.item)) {
        this.setInitDate();
      }
    },
  },
  created () {
    this.setInitDate();
  },
  methods: {
    updateItemAvailableByDate (startDate: string, endDate: string) {
      this.initDate.startDateInit = startDate;
      this.initDate.endDateInit = endDate;
      this.$emit('updateAvailableByDate', {
        itemId: this.item.id,
        startDate,
        endDate,
      });
    },
    setInitDate () {
      this.initDate.startDateInit = this.item.start_date ? date(this.item.start_date) : refStartDate().value;
      this.initDate.endDateInit = this.item.end_date ? date(this.item.end_date) : refEndDate().value;
    },
    openDetails () {
      this.$emit('openDetails', this.item);
    },
  },
});
