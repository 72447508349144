<template>
  <section class="mt-8 px-3 mb-8">
    <h1 class="font-black text-4xl mb-5 font-secondary text-secondary uppercase">Órdenes de renta</h1>
    <OperationsList v-if="!isLoading" :operations="operations?.data" />
    <div v-else class="border rounded-lg flex justify-center items-center space-x-2 p-3">
      <r-icon type="spinner" class="h-5 w-5" />
      <span>Cargando...</span>
    </div>
    <Pagination class="mt-2" :data="operations" :max="7" auto-hide @page="load" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import Pagination from '@/components/Pagination';
import { useOperations, getOperations } from '@/store/operations';
import OperationsList from '@/components/Operations';
import { ref } from 'vue';

export default defineComponent({
  name: 'OperationsView',
  components: {
    Pagination,
    OperationsList,
  },
  setup() {
    const operations = useOperations();
    const isLoading = ref(false);
    const currentPage = ref(1);
    const load = (page: number = 1) => {
      if (isLoading.value) return;
      isLoading.value = true;
      getOperations(page).then(() => {
        currentPage.value = page;
      }).finally(() => {
        isLoading.value = false;
      });
    };

    load();

    return {
      operations,
      load,
      isLoading,
      currentPage,
    };
  },
});
</script>

<style></style>
