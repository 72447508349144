<template>
  <section class="md:px-20">
    <h1 class="uppercase font-secondary font-bold text-secondary text-3xl">Editar perfil</h1>
    <form v-if="form !== null" @submit.prevent="onSubmit">
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Tipo Fiscal
        </LabelFormProfile>
        <SelectFormProfile
          v-model="form.fiscal_type"
          :options="[{name: 'Persona Fisica', value: 'fisica'}, {name: 'Persona Moral', value: 'moral'}]"
        />
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Nombre / Razon social
        </LabelFormProfile>
        <InputFormProfile id="name" v-model="form.name" name="name" />
        <ErrorField>{{ errors.name }}</ErrorField>
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Email
        </LabelFormProfile>
        <InputFormProfile id="email" :value="email" name="email" type="email" disabled />
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Teléfono
        </LabelFormProfile>
        <InputFormProfile id="phone" v-model="form.phone" type="tel" />
        <ErrorField>{{ errors.phone }}</ErrorField>
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          RFC
        </LabelFormProfile>
        <InputFormProfile id="rfc" v-model="form.rfc" />
        <ErrorField>{{ errors.rfc }}</ErrorField>
      </div>
      <h2 class="uppercase text-primary font-bold text-lg mt-9">Dirección</h2>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Calle
        </LabelFormProfile>
        <InputFormProfile id="street" v-model="form.street" />
        <ErrorField>{{ errors.street }}</ErrorField>
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Numero
        </LabelFormProfile>
        <InputFormProfile id="address_number" v-model="form.address_number" name="address_number" />
        <ErrorField>{{ errors.address_number }}</ErrorField>
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Colonia
        </LabelFormProfile>
        <InputFormProfile id="col" v-model="form.col" />
        <ErrorField>{{ errors.col }}</ErrorField>
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Municipio
        </LabelFormProfile>
        <InputFormProfile id="mun" v-model="form.mun" />
        <ErrorField>{{ errors.num }}</ErrorField>
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Codigo Postal
        </LabelFormProfile>
        <InputFormProfile id="postal_code" v-model="form.postal_code" />
        <ErrorField>{{ errors.postal_code }}</ErrorField>
      </div>
      <div class="mt-6 px-3">
        <LabelFormProfile>
          Estado
        </LabelFormProfile>
        <InputFormProfile id="state" v-model="form.state" />
        <ErrorField>{{ errors.state }}</ErrorField>
      </div>
      <button type="submit" class="my-6 btn btn-success float-right" :disabled="!meta.valid || loading">
        <r-icon v-show="loading" type="spinner" class="h-5 inline-block" />
        Guardar
      </button>

      <router-link
        class="my-6 btn btn-white-secondary float-left "
        :to="{ name: 'profile' }"
      >
        Cancelar
      </router-link>
    </form>
  </section>
</template>

<script lang="ts">
import LabelFormProfile from '@/components/UI/LabelFormProfile.vue';
import InputFormProfile from '@/components/UI/InputFormProfile.vue';
import SelectFormProfile from '@/components/UI/SelectFormProfile.vue';
import ErrorField from '@/components/UI/ErrorField.vue';
import { update, useUser } from '@/store/security';
import { useForm, useField } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LabelFormProfile,
    InputFormProfile,
    SelectFormProfile,
    ErrorField,
  },
  setup() {
    const user = useUser();

    const schema = {
      name: 'min:3|max:60|required',
      phone: 'max:20|alpha_num',
      rfc: 'max:18|alpha_num',
      address_number: 'max:7|numeric',
      street: 'max:30|alpha_num',
      col: 'max:30|alpha_spaces',
      mun: 'max:30|alpha_spaces',
      postal_code: 'max:5|numeric',
      state: 'max:20|alpha_spaces',
    };

    const labels = {
      name: 'Nombre',
      phone: 'Teléfono',
      rfc: 'RFC',
      address_number: 'Numero Exterior',
      street: 'Calle',
      col: 'Colonia',
      mun: 'Municipio',
      postal_code: 'Codigo Postal',
      state: 'Estado',
    };

    const { errors, values, meta } = useForm({
      initialValues: {...user.value},
      validationSchema: schema,
    });

    Object.entries(labels).forEach(([key, val])=>{
      useField(key, null, {label: val});
    });

    return {
      errors,
      meta,
      email: user.value?.email,
      form: values,
    };
  },
  data: () =>({
    loading: false,
  }),
  methods: {
    onSubmit() {
      this.loading = true;
      update(this.form).then(()=> this.loading = false);
      this.$router.push({ name: 'profile' });
    },
  },
});
</script>

<style></style>
