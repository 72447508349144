
import SideNavLink from '@/components/navigation/SideNavLink.vue';
import HeaderProfile from '@/components/Header/HeaderProfile.vue';
import VueFooter from '@/components/Footer.vue';
import { profile, useUser } from '@/store/security';
import { defineComponent, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: 'Profile',
  components: {
    SideNavLink,
    HeaderProfile,
    VueFooter,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const user = useUser();
    watch(user, (newValue) => {
      if(newValue === null) {
        router.push({name: 'Home'});
      }
    });
    const routeName = route.name as string;
    return { user, routeName };
  },
  data: () => ({
    sections: [
      { name: 'profile', label: 'Datos Generales', icon: 'profile' },
      { name: 'references', label: 'Referencias', icon: 'phone' },
      { name: 'documentation', label: 'Documentación', icon: 'documents'  },
      { name: 'edit_profile', label: 'Editar Perfil', icon: 'update'  },
      { name: 'operations', label: 'Órdenes de renta', icon: 'search'  },
    ],
  }),
  async mounted() {
    await profile();
  },
  methods: {
    changeSection(event: Event) {
      this.$router.push({ name: (event.currentTarget as HTMLInputElement)?.value });
    },
  },
});
